import React, { useEffect, useState } from 'react'
import { navigate } from "gatsby"
import { useLocation } from '@reach/router';

import {
  dataStore,
  pageStore
} from "state/store-zustand";
import usePageLoading from 'components/utils/usePageLoading'
import useContextTheme from 'components/utils/useContextTheme'

import Footer from "components/footer"
import PostHeader from "components/header/postHeader"
import SEO from "components/seo"
import Breadcrumbs from 'components/elements/breadcrumbs'
import { linksToBreadcrumbs } from 'components/utils/schemaUtils'


import PlaceDetails from './placeDetails'
import "styles/pages.scss"
import "styles/details.scss"

const PlaceLayout = ({
  placeID = null,
  ...props
}) => {

  const pageLoader = usePageLoading()

  const embedded = pageStore((state) => state.embedded)
  const isMobile = pageStore((state) => state.isMobile)
  const isTablet = pageStore((state) => state.isTablet)
  const placeLayout = pageStore((state) => state.placeLayout)
  const section = pageStore((state) => state.section)
  const themeContext = useContextTheme()
  const { theme, themeClass } = themeContext

  const eventsOnly = embedded && section == 'events' ? true : false

  const currentPlace = dataStore((state) => state.placeCurrent)
  const { properties } = currentPlace || {}

  const {
    address,
    categories,
    city,
    description,
    links: ticketLinks,
    location,
    name,
    opening_hours,
    price,
    slug,
    tips,
    vibes,
    vibemap_images,
    url
  } = properties || {} // Safely destructure

  // Replace ID with Slug
  const urlLocation = useLocation()
  useEffect(() => {
    const pagePath = urlLocation.pathname
    if (slug && !pagePath.includes(slug)) {
      const newURL = pagePath.replace(placeID, slug)
      navigate(newURL, { replace: false })
    }
  }, [slug])

  const [links, setLinks] = useState([])
  const [breadcrumbItems, setBreadcrumbItems] = useState()

  // Add breadcrumbs
  useEffect(() => {
    const crumbs = []

    if (city) {
      crumbs.push({
        name: city.name,
        slug: city.slug,
        type: 'city'
      })
    }

    const breadcrumbItems = linksToBreadcrumbs(crumbs)
    setLinks(crumbs)
    setBreadcrumbItems(breadcrumbItems)

  }, [])

  const breadcrumbs = <Breadcrumbs links={links} />
  const schemaData = []
  if (breadcrumbItems) {
    schemaData.push(breadcrumbItems)
  }

  const title = `${name} | Vibemap`
  const detailsOnly = placeLayout == 'details' ? true : false
  const mapOnly = placeLayout == 'map' ? true : false
  //console.log('DEBUG placeLayout ', placeLayout, detailsOnly);
  const isLoaded = pageLoader == 'loaded' ? true : false
  const showHeader = embedded != true && isLoaded

  const classPage = `page ${embedded ? 'embedded' : ''} ${themeClass}`
  console.log('is page embedded ', embedded, pageLoader);

  return (
    <>
      <SEO
        lang="en-US"
        schemaData={schemaData}
        title={title}
      />
      <main className={classPage}>
        {showHeader
          ? <PostHeader
            showSave={true}
            breadcrumbs={breadcrumbs}
            url={`/places/details/${placeID}`} />
          : null
        }

        {/* TODO: Is loading */}

        <PlaceDetails
          id={placeID}
          detailsOnly={detailsOnly}
          eventsOnly={eventsOnly}
          mapOnly={mapOnly}
        />

      </main>
      {embedded != true
        ? <Footer />
        : null
      }

    </>
  )
}

export default PlaceLayout
import React, { useEffect, useState, useRef, lazy, Suspense } from "react"


import { TemplateContextProvider } from 'components/post/TemplateProvider'
import { PlacesContextProvider } from 'components/post/PlacesProvider'

import PlaceLayout from "./placeLayout";
import "./place.scss";


const Place = ({
  data,
  id,
  pageContext,
  pageResources,
  serverData,
  params,
  ...props
}) => {

  // const { place = null } = pageContext
  const preloaded = serverData?.preloaded

  const [placeDetails, setPlaceDetails] = useState(serverData?.place
    ? serverData?.place
    : null) // TODO: Set default from SSR data

  const [hasError, setHasError] = useState(false) // TODO: Set default from SSR data

  // Set ID from param or pre-fetched data
  const placeID = id
    ? id
    : params.id
      ? params.id
      : null

  return (
    <div>
      <TemplateContextProvider tryLocation={false}>
        <PlacesContextProvider>
          <PlaceLayout placeID={placeID} />
        </PlacesContextProvider>
      </TemplateContextProvider>
    </div>
  )
}
export default Place

/*
export async function getServerData({ params, ...props}) {
  console.log('!!! getServerData.. ');

  const { id } = params
  const errorResponse = {
    status: 500,
    headers: {},
    props: { preloaded: true, place: null }
  }

  try {
    const response = await fetchPlacesDetails(id)
      .catch(e => {
        console.log(`Error with place `, id, e)
        setHasError(true)
      })

    const details = response?.data
    console.log('Place details SSR ', details);
    if (details) {
      return {}
      return {
       props: {
        preloaded: true,
        place: details
       },
      }
    } else {
      return errorResponse
    }

  } catch (error) {
    console.log('error ', error);
    return errorResponse
  }
}
*/